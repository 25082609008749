@font-face {
  font-family: 'Gilroy';
  src: url('./assets/Gilroy-SemiBold.otf') format('truetype');
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* ============ flip hover styles ============ */
.flip-card:hover {
  transform: rotateY(180deg);
  transform-style: preserve-3d;
}

.flip-card .card-back {
  transform: rotateY(180deg);
}

.wallet-adapter-dropdown {
  @apply font-normal text-base rounded w-full !important;
}

.wallet-adapter-button {
  @apply bg-indigo-500 hover:bg-indigo-700 py-2 px-2 justify-center w-full !important;
}

.swv-modal-wrapper {
  @apply px-4;
}

.swv-dropdown {
  @apply w-full;
}

.swv-dropdown > .swv-button-trigger {
  @apply bg-red-500 hover:bg-red-700 !important;
}